import {graphql, Link} from "gatsby";
import React, {FC} from "react";
import EmailAddress from "../components/email-address";
import Layout from "../components/layout/layout-default";
import {privacyPolicySlug} from "../helpers/slug-helper";
import {PostalAddress} from "../types";

interface ImprintPageProps {
    data: {
        site: {
            siteMetadata: {
                author: {
                    address: PostalAddress;
                };
            };
        };
    };
}

export const query = graphql`
    query {
        site {
            siteMetadata {
                author {
                    address {
                        name
                        street
                        zip
                        city
                        country
                        emailEncrypted
                        taxNumber
                        vatId
                    }
                }
            }
        }
    }
`;

const ImprintPage: FC<ImprintPageProps> = ({data}) => (
    <Layout>
        <section className="container mx-auto my-16 px-4 space-y-6 max-w-xl">
            <h1>{"Imprint"}</h1>
            <p>{"Imprint according to the Telemediengesetz (TMG) of the Federal Republic of Germany."}</p>
            <div>
                <h2>{"Address and contact information"}</h2>
                <p>{"Responsible for content according to § 55 II RStV:"}</p>
                <address>
                    <p>
                        {data.site.siteMetadata.author.address.name}
                        <br />
                        {data.site.siteMetadata.author.address.street}
                        <br />
                        {`${data.site.siteMetadata.author.address.zip} ${data.site.siteMetadata.author.address.city}`}
                        <br />
                    </p>
                </address>
                <p>
                    {"Email: "}
                    <EmailAddress emailEncrypted={data.site.siteMetadata.author.address.emailEncrypted} />
                </p>
                <p>
                    {"Value added tax identification number according to § 27a UStG (Turnover Tax Act):"}
                    <br />
                    {data.site.siteMetadata.author.address.vatId}
                </p>
            </div>
            <div className="space-y-6">
                <h2>{"Disclaimer"}</h2>
                <div>
                    <h3>{"Liability for contents"}</h3>
                    <p>
                        {
                            "I try to keep the contents of this page up to date. Despite careful processing, liability is excluded. As a service provider I am responsible according to § 7 Abs.1 TMG for own contents on these sides after the general laws. According to §§ 8 to 10 TMG I am not obliged as a service provider to monitor transmitted or stored third-party information. As soon as I become aware of any legal infringements, I will remove these contents immediately. I assume liability in this respect only from the time I become aware of a possible infringement."
                        }
                    </p>
                </div>
                <div>
                    <h3>{"Liability for links"}</h3>
                    <p>
                        {
                            "This page contains links to external websites of third parties, on whose contents I have no influence. For the contents of the linked pages always the respective provider or operator of the pages is responsible. For the contents and the correctness of the information of linked websites of foreign information providers no guarantee is taken over. The linked pages were checked for possible violations of the law without complaint at the time of linking. As soon as we become aware of any legal infringements, we will remove such links immediately."
                        }
                    </p>
                </div>
            </div>
            <div>
                <h3>{"Copyright"}</h3>
                <p>
                    {
                        "The contents and works on these pages created by the site operator are subject to German copyright law. Duplication, processing, distribution and any form of commercialization of such material beyond the scope of the copyright law shall require the prior written consent of Sascha Klatt."
                    }
                </p>
            </div>
            <div>
                <h3>{"Data protection"}</h3>
                <p>
                    {"see "} <Link to={privacyPolicySlug()}>{"privacy policy"}</Link>
                </p>
            </div>
        </section>
    </Layout>
);

export default ImprintPage;
